:root.deals-theme {
  // button
  --button-border-radius: 8px;
  --button-primary-color: #f60;
  --button-primary-color-active: #732f02;
  --button-primary-color-hover: #d66215;
  --button-primary-color-text: #fff;
  --button-primary-outline-color-text: #f60;
  --button-primary-text-color-text: #f60;
  --button-secondary-color: #ffede1;
  --button-secondary-color-active: #fc9b59;
  --button-secondary-color-hover: #fdc29b;
  --button-secondary-color-text: #f60;
  --button-secondary-outline-color-text: #f60;
  --button-secondary-text-color-text: #f60;
  // card
  --card-border-radius: 8px;
  --card-border-width: 0;
  --card-image-border-radius: 8px;
  // carousel
  --carousel-heading-font-weight: 600;
  // color
  --color-primary: #f60;
  --color-primary-25: #ffede1;
  --color-primary-50: #fdc29b;
  --color-primary-75: #fc9b59;
  --color-primary-100: #f60;
  --color-primary-125: #d66215;
  --color-primary-150: #732f02;
  --color-primary-175: #461d01;
  // font
  --font-family-display: 'Source Sans Pro';
  // heading
  --heading-group-section-font-weight: 600;
  --heading-group-section-line-height: 1.25;
  // inputs
  --inputs-border-radius: 8px;
  --inputs-color-background-active: #f60;
  // pagination
  --pagination-item-color-background-active: #f60;
  // running
  --running-head-color-divider: #ba975f;
  --running-head-color-text: #ba975f;
  // table
  --table-border-radius: 8px;
}
