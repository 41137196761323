/* stylelint-disable-next-line scss/at-rule-no-unknown */
@layer base {
  @font-face {
    font-display: swap;
    font-family: Literata;
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/literata/literata-300.woff2') format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: Literata;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/literata/literata-400.woff2') format('woff2');
  }
}
