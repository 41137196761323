:root.verrast-theme {
  // button
  --button-border-radius: 0;
  --button-primary-color: #d9006c;
  --button-primary-color-active: #7c003e;
  --button-primary-color-hover: #ba005d;
  --button-primary-color-text: #fff;
  --button-primary-outline-color-text: #d9006c;
  --button-primary-text-color-text: #d9006c;
  --button-secondary-color: #fbe6f0;
  --button-secondary-color-active: #e96dab;
  --button-secondary-color-hover: #f4b6d5;
  --button-secondary-color-text: #d9006c;
  --button-secondary-outline-color-text: #d9006c;
  --button-secondary-text-color-text: #d9006c;
  // card
  --card-border-radius: 0;
  --card-border-width: 0;
  --card-image-border-radius: 0;
  // carousel
  --carousel-heading-font-weight: 600;
  // color
  --color-primary: #d9006c;
  --color-primary-25: #fbe6f0;
  --color-primary-50: #f4b6d5;
  --color-primary-75: #e96dab;
  --color-primary-100: #d9006c;
  --color-primary-125: #ba005d;
  --color-primary-150: #7c003e;
  --color-primary-175: #3e001f;
  // font
  --font-family-display: 'Source Sans Pro';
  // heading
  --heading-group-section-font-weight: 600;
  --heading-group-section-line-height: 1.25;
  // inputs
  --inputs-border-radius: 0;
  --inputs-color-background-active: #d9006c;
  // pagination
  --pagination-item-color-background-active: #d9006c;
  // running
  --running-head-color-divider: #ba975f;
  --running-head-color-text: #ba975f;
  // table
  --table-border-radius: 0;
}
