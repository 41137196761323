/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-type-no-unknown */
@layer base {
  p {
    @apply block mb-3;
  }

  blockquote {
    @apply relative mb-8 ml-8 italic md:text-lg before:absolute before:-ml-8 before:text-5xl md:before:text-6xl before:font-bold before:text-accent-100 before:content-['“'];
     
    p {
      @apply block ml-5 pl-5 border-l border-solid border-ui-100;
    }
  }
}

markdown ul,
markdown ol,
.richtext ol,
ul.styled-list,
ol.styled-list {
  @apply list-decimal list-inside children:marker:text-dark-primary pl-2.5;

  p {
    @apply inline;
  }
}

markdown ul,
ul.styled-list {
  @apply list-disc; }
