/* stylelint-disable-next-line scss/at-rule-no-unknown */
@layer base {
  @font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-300.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-400.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-600.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-700.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/source-sans-pro/source-sans-pro-900.woff') format('woff');
  }
}
