/* stylelint-disable-next-line scss/at-rule-no-unknown */
@layer base {
  @font-face {
    font-display: swap;
    font-family: Melbourne;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/melbourne/melbourne-400.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: Melbourne;
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/melbourne/melbourne-700.woff') format('woff');
  }
}
