.alert--top-left {
  @apply max-w-full w-auto md:w-96 fixed top-0 left-4 md:left-auto right-4 md:right-auto mt-4 z-50;
}

.alert--top-center {
  @apply max-w-full w-auto md:w-96 fixed top-0 left-4 right-4 mt-4 md:left-0 md:right-0 md:mx-auto z-50;
}

.alert--top-right {
  @apply left-4 md:left-auto max-w-full fixed top-0 right-4 w-auto md:w-96 mt-4 z-50;
}

.alert--bottom-left {
  @apply max-w-full w-auto md:w-96 fixed bottom-0 left-4 md:left-auto right-4 md:right-auto mb-2 z-50;
}

.alert--bottom-center {
  @apply max-w-full w-auto md:w-96 fixed bottom-0 left-4 right-4 mb-2 md:left-0 md:right-0 md:mx-auto z-50;
}

.alert--bottom-right {
  @apply left-4 md:left-auto max-w-full fixed bottom-0 right-4 w-auto md:w-96 mb-2 z-50;
}
