:root.hotels-theme {
  // button
  --button-border-radius: 0;
  --button-primary-color: #4c00e4;
  --button-primary-color-active: #2b0082;
  --button-primary-color-hover: #4100c4;
  --button-primary-color-text: #fff;
  --button-primary-outline-color-text: #4c00e4;
  --button-primary-text-color-text: #4c00e4;
  --button-secondary-color: #ede6fc;
  --button-secondary-color-active: #996df0;
  --button-secondary-color-hover: #ccb6f7;
  --button-secondary-color-text: #4c00e4;
  --button-secondary-outline-color-text: #4c00e4;
  --button-secondary-text-color-text: #4c00e4;
  // card
  --card-border-radius: 0;
  --card-border-width: 0;
  --card-image-border-radius: 0;
  // carousel
  --carousel-heading-font-weight: 300;
  // color
  --color-primary: #4c00e4;
  --color-primary-25: #ede6fc;
  --color-primary-50: #ccb6f7;
  --color-primary-75: #996df0;
  --color-primary-100: #4c00e4;
  --color-primary-125: #4100c4;
  --color-primary-150: #2b0082;
  --color-primary-175: #160041;
  // font
  --font-family-display: 'Literata';
  // heading
  --heading-group-section-font-weight: 400;
  --heading-group-section-line-height: 1.25;
  // inputs
  --inputs-border-radius: 0;
  --inputs-color-background-active: #4c00e4;
  // pagination
  --pagination-item-color-background-active: #4c00e4;
  // running
  --running-head-color-divider: #ba975f;
  --running-head-color-text: #ba975f;
  // table
  --table-border-radius: 0;
}
