@use 'sass:meta';
/* stylelint-disable no-invalid-position-at-import-rule */
/* stylelint-disable scss/at-rule-no-unknown */
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css('fonts/index');
@include meta.load-css('typography/typography');

/* NOTE: https://community.adobe.com/t5/adobe-xd-discussions/font-weights-appearing-heavier-in-browser-chrome-and-safari-then-in-xd/td-p/11293936 */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* NOTE: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
.grecaptcha-badge { display: none !important; }
